<template>
    <div class="login">

        <header class="header">
            <a class="back" href="/login"></a>
            จดทะเบียน
        </header>

        <div class="mui-content">
            <form id="back-form" onsubmit="return false">
                <div class="mui-input-group regfrm">

                    <div class="mui-input-row">
                        <label>หมายเลขโทรศัพท์</label>
                        <input id="account" name="account" @input="inname" type="number" class="mui-input-clear mui-input" placeholder="กรุณาใส่หมายเลขโทรศัพท์" data-input-clear="2">
                        <span class="mui-icon mui-icon-clear mui-hidden"></span>
                    </div>
              
                    <div class="mui-input-row pr">
                        <label>ตั้งรหัสผ่าน</label>
                        <input :type='pwdFlag?"password":"text"' id="password" name="password" @input="inpass" class="mui-input-clear mui-input" placeholder="กรุณาตั้งรหัสผ่าน 6-16 หลัก" data-input-clear="3">
                        <span class="mui-icon mui-icon-clear mui-hidden"></span>
                        <i :class="pwdFlag?textIcon:pwdIcon" @click="changeIcon" id='switch'></i>
                    </div>

                    <!--图形验证码-->
                    <div class="mui-input-row pr">
                        <label for="account"> รหัสยืนยันภาพ</label>
                        <input id="verifycode" name="tpyzm" @input="inverifycode" type="text" class="mui-input-clear mui-input" placeholder="โปรดป้อนรหัสยืนยันภาพ" data-input-clear="2">
                        <img :src="verifycode_img" class="chkimg" style="position:absolute;right:10px;top:9px;height: 28px;" @click="change_img">
                    </div>

                </div>
                <article class="msub">
                    <input :class="{greyedOut:isGreyedOut}" @click="reg" type="submit" value="ลงทะเบียน" style="background-color: #003e83;width: 100%;">
                </article>
                <!-- 提示 -->
                <div style="display: none;top: 45%;" class="errdeo" id="messageBox">
                </div>
            </form>
        </div>

    </div>

</template>

<script>
export default {
    name: "Register",
    props: {
        msg: String,
    },
    data() {
        return {
            isGreyedOut: false,
            verifycode_img: "",
            user: {
                phone: "",
                password: "",
                verifycode: "",
            },
            pwdFlag:true,//密码标示 true表示当前是密码形式
            textIcon:'seltarr pab password_icon_off',//展示图标
            pwdIcon:'seltarr pab password_icon_on',//隐藏图标
        };
    },
    created() {
        // 初始化操作，如获取数据
        this.getverifycode();
    },
    methods: {
        //改变密码表示
        changeIcon:function(){
            //密码标示取反即可
            this.pwdFlag=!this.pwdFlag;
        },
        getverifycode() {
            var capKey = Math.random() * 100;
            // 服务器
            this.verifycode_img = "/index.php?m=Common&a=verify&time="+capKey;
            // 本地
            // this.verifycode_img = "http://vue.test/index.php?m=Common&a=verify&time="+capKey;
        },

        inname(e) {
            this.user.phone = e.target.value;
        },
        inpass(e) {
            this.user.password = e.target.value;
        },
        inverifycode(e) {
            this.user.verifycode = e.target.value;
        },

        // 刷新验证码
        change_img() {
            var capKey = Math.random() * 100;
            // 服务器
            this.verifycode_img = "/index.php?m=Common&a=verify&time="+capKey;
            // 本地
            // this.verifycode_img = "http://vue.test/index.php?m=Common&a=verify&time="+capKey;
        },

        // 注册
        async reg() {
            if (this.user.phone == "") {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "danger",
                    title: "error",
                    text: "โปรดป้อนหมายเลขโทรศัพท์ที่ถูกต้อง",
                });
                return false;
            }

            if (this.user.password == "") {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "danger",
                    title: "error",
                    text: "รหัสผ่านไม่ได้ว่างเปล่า",
                });
                return false;
            }

            if (this.user.verifycode.trim().length <= 0) {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "danger",
                    title: "error",
                    text: "แคปช่าไม่ว่างเปล่า",
                });
                return false;
            }

            var re = /^[a-zA-Z0-9_]{6,16}$/ // 密码6~16位 必须包含大小写字母和数字的组合
            var pass = re.test(this.user.password);
            if(!pass){
              this.$vs.notification({
                position:'top-center',
                icon:'<span class="material-icons">error</span>',
                color:'warn',
                title: 'error',
                text: `กรุณาตั้งรหัสผ่าน 6-16 หลัก`,
              })
              return false;
            }

            this.isGreyedOut = true;

            const tishi = this.$vs.loading({
                type: "circles",
                color: "#d5397b",
                text: "กรุณารอสักครู่!",
            });

            // 注册接口
            var result = await this.$proxy.regdit(this.user);
            tishi.close();
            this.isGreyedOut = false;

            if (result.status === 1) {
                const loading = this.$vs.loading({
                    type: "circles",
                    color: "#d5397b",
                    text: "การลงทะเบียนสำเร็จ!",
                });
                // this.hasOpenLoading = true;
                setTimeout(() => {
                    loading.close();
                    // this.hasOpenLoading = false;
                }, 800);
                window.open("index.php?m=Index&a=index", "_self");
            } else if (result.code == 0) {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "warn",
                    title: "error",
                    text: result.msg,
                });
                return false;
            }
        },

        // 去登录
        goLogin() {
            this.$router.push("/login");
        },
    },
};
</script>

<style scoped>
.greyedOut {
  background-color: grey;
  color: white;
  pointer-events: none; /* 阻止点击事件 */
  cursor: default; /* 改变鼠标指针为默认样式 */
}
.mui-input-group .mui-input-row {
    height: 45px;
}

.mui-input-row label {
    padding: 13px 15px;
}

.regfrm .mui-input-row input {
    height: 45px;
}

.mui-input-row:last-child:after {
    height: 0;
}

@media screen and (max-width: 348px) {
    .regfrm .mui-input-row label {
        font-size: 14px;
        padding: 15px 15px;
    }
}

.get_phonecode {
    position: absolute;
    right: 10px;
    top: 9px;
    height: 28px;
    background-color: #ff471a;
    color: white;
    padding: 0.1rem 1rem;
    border-radius: 0.8rem;
}
</style>
