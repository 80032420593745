<template>
    <div class="login">

        <header class="mui-bar mui-bar-nav hnav lognav">
            <h1 class="mui-title">เข้าสู่ระบบ</h1>
        </header>

        <div class="mui-content">
            <div class="mui-log">
                <img src="../assets/5.jpg" alt="">
            </div>
            <article id="tabs" class="logtabs">
                <section class="allpic" style="display:block;">
                    <form id="login-form" onsubmit="return false">

                        <div class="mui-input-group lgfrm">
                            <div class="mui-input-row">
                                <label for="account"><span class="phone"></span></label>
                                <input id="account" name="account" @input="inname" type="number" class="mui-input-clear mui-input" placeholder="กรุณาใส่หมายเลขโทรศัพท์" data-input-clear="2" data-flag="1"><span class="mui-icon mui-icon-clear mui-hidden"></span>
                            </div>
                            <div class="mui-input-row pr">
                                <label for="password"><span class="pwds"></span></label>
                                <input :type='pwdFlag?"password":"text"' id="password" name="password" @input="inpass" class="mui-input-clear mui-input" placeholder="กรุณาใส่รหัสผ่าน" data-input-clear="3" data-flag="1">
                                <span class="mui-icon mui-icon-clear mui-hidden"></span>
                                <i :class="pwdFlag?textIcon:pwdIcon" @click="changeIcon" id="switch"></i>
                            </div>
                        </div>
                        <div class="msub">
                            <input id="btn" class="submit" type="submit" @click="login" value="เข้าสู่ระบบ">
                            <input class="submit" @click="register" type="submit" value="จดทะเบียน">
                        </div>
                    </form>

                </section>
            </article>
        </div>
    </div>
</template>

<script>
export default {
    name: "Login",
    props: {
        msg: String,
    },
    data() {
        return {
            user: {
                phone: "",
                password: "",
            },
            pwdFlag:true,//密码标示 true表示当前是密码形式
            textIcon:'seltarr pab password_icon_off',//展示图标
            pwdIcon:'seltarr pab password_icon_on',//隐藏图标
        };
    },
    created() {
        // 初始化操作，如获取数据
        // this.fetchData();
    },
    methods: {
        //改变密码表示
        changeIcon:function(){
            //密码标示取反即可
            this.pwdFlag=!this.pwdFlag;
        },
        // 获取用户名和密码
        inname(e) {
            this.user.phone = e.target.value;
        },
        inpass(e) {
            this.user.password = e.target.value;
        },
        // 跳转到注册
        register() {
            this.$router.push({ path: "/register" });
        },
        // 跳转到语言
        lang() {
            window.open("/index/user/lang", "_self");
        },
        // 登录
        async login() {
            if (this.user.phone === "" || this.user.password === "") {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "warn",
                    // title: "错误",
                    text: "ชื่อบัญชีหรือรหัสผ่านไม่ถูกต้อง",
                });
                return false;
            }
            // 登录接口
            var result = await this.$proxy.login(this.user);
            if (result.status === 1) {
                const loading = this.$vs.loading({
                    type: "circles",
                    color: "#d5397b",
                    text: "เข้าสู่ระบบสำเร็จ",
                });
                this.hasOpenLoading = true;
                setTimeout(() => {
                    loading.close();
                    this.hasOpenLoading = false;
                }, 2000);
                window.open("index.php?m=Index&a=index", "_self");
            } else {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons">error</span>',
                    color: "warn",
                    text: result.msg,
                });
            }
        },
    },
};
</script>


<style scoped>
.login .tips {
    position: fixed;
    bottom: 50px;
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.cont .tips i {
    font-style: inherit;
    color: rgb(101, 168, 247);
}

.seleLangs {
    position: fixed;
    width: 100%;
    right: 7px;
    z-index: 999;
    top: 10px;
}
.imgList {
    position: absolute;
    right: 17px;
    z-index: 999;
    top: 20px;
}
.imgList img {
    display: flex;
    width: 25px;
    height: 25px;
}

.mui-input-group .mui-input-row {
    height: 45px;
}

.lgfrm .mui-input-row label ~ input {
    width: 82%;
    font-size: 16px;
    height: 45px;
}

.mui-input-row label {
    padding: 12px 15px;
}

.gswitch {
    bottom: 8px;
}

.mui-input-row:last-child:after {
    height: 0px;
}

.log label {
    padding: 4px 20px 0 10px;
    height: 45px;
    line-height: 20px;
    border-bottom: none;
}

.log {
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
}

.borbottom {
    border-bottom: 1px solid rgba(239, 239, 239, 0.8) !important;
}

.log .ckbtn,
.log .ckbtnOff {
    top: 100px;
}

.log label input {
    height: 38px;
    line-height: 20px;
    padding-left: 12%;
}

.log label .phopwd {
    width: 100%;
}
.msub {
    height: 80px;
    display: flex;
    padding: 0;
    justify-content: space-around;
    align-items: center;
}

.msub .submit {
    width: 40%;
    height: 50px;
    background-color: #003e83;
}

.mui-content {
    background-color: #f7f7f7;
}

.pwtxt {
    color: #333;
}
</style>
